import { InfoIcon } from "@primer/octicons-react";
import { useState } from "react";
import RankList from "./DashboardInsights/RankList";

const DashboardCard = ({
  leads,
  percentage,
  metricName,
  leadText,
  colorLight,
  colorMedium,
  colorDark,
  tooltipText,
  rank,
}: {
  colorLight: string;
  colorMedium: string;
  colorDark: string;
  leads: string;
  percentage?: string;
  metricName: string;
  leadText: string;
  tooltipText?: string;
  rank?: string;
}) => {
  const [showModal, setShowModal] = useState(false);

  const show = !!rank && !!parseInt(leads);
  return (
    <div
      className={`h-40 w-56 rounded-lg ${colorLight} px-6 py-4 ${colorDark}`}
    >
      <div className="flex justify-end gap-2">
        <div
          className={`w-full text-right text-xs font-semibold ${colorMedium}`}
        >
          {leadText}
        </div>
        {tooltipText && (
          <div className="group flex">
            <InfoIcon />
            <div className="relative z-50 opacity-0 group-hover:opacity-100">
              <div className="absolute -ml-40 -mt-6 whitespace-nowrap bg-white px-1 text-sm">
                {tooltipText}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-2xl font-bold">{leads}</div>

      <div className="h-5 text-sm font-semibold">{percentage}</div>
      <button
        className={`${show && "text-accent"} py-3`}
        disabled={!show}
        onClick={() => setShowModal(true)}
      >
        {metricName}
      </button>
      <div>
        {showModal && show && (
          <RankList
            showModal={showModal}
            setShowModal={setShowModal}
            rank={rank}
          />
        )}
      </div>
    </div>
  );
};
export default DashboardCard;
