import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import CampaignTeams from "~/components/campaigns/MainPage/CampaignTeams";
import CampaignUsers from "~/components/campaigns/MainPage/CampaignUsers";
import TeamsTable from "~/components/campaigns/MainPage/TeamsTable";
import UsersTable from "~/components/campaigns/MainPage/UsersTable";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { RouterInputs, api } from "~/utils/api";

import EditSelection from "~/components/campaigns/Utils/EditSelection";
import { useLogger } from "~/providers/LoggerProvider";
import {
  Enums,
  pluralize,
  readableErrorFromScreamingSnake,
} from "@openqlabs/utils";
import useCampaignListParams from "./SharedTable/hooks/useCampaignListParams";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useDismissible from "~/hooks/useDissmissable";
import Notification from "~/components/base/Notification";
import { useCampaigns } from "~/providers/CampaignsProvider";
import NoResults from "./NoResults";
import UltraLightTable from "./UltraLightTable";

export type CampaignListParamsType =
  RouterInputs["targetContact"]["getTargetsByCampaignId"];

const TargetInfoDashboard = ({
  activeHeaderMenu,
  showTable,
}: {
  activeHeaderMenu: string;
  showTable: boolean;
}) => {
  const router = useRouter();
  const type = activeHeaderMenu === "Teams" ? "repo" : "user";
  const campaignId = router.query.campaignId;
  const { campaigns } = useCampaigns();

  const logger = useLogger();
  const { activeTeamAccount } = useTeamAccount();
  const { data: targetBatchCreationFailCounts } =
    api.targetBatchCreation.getTargetBatchCreationErrorCounts.useQuery({
      teamAccountId: activeTeamAccount.id,
      campaignId: campaignId as string,
      type,
    });

  const [loading, setLoading] = useState<boolean>(true);

  const campaignIds =
    typeof campaignId === "string"
      ? [campaignId]
      : campaigns.map((c) => c.id) ?? [];

  const { data: campaignLightModes } = api.campaign.campaignModesByIds.useQuery(
    {
      campaignIds: campaignIds,
      teamAccountId: activeTeamAccount.id,
    }
  );

  const showEngagement = campaignLightModes?.includes(false) ?? false;

  const { user: userCampaignListParams, repo: repoCampaignListParams } =
    useCampaignListParams();
  const {
    data: targetContactUsers,
    fetchNextPage: fetchNextUserPage,
    hasNextPage: hasNextUserPage,
    isLoading: isLoadingUsers,
    isFetchingNextPage: isFetchingNextUsersPage,
  } = api.targetContact.getTargetsByCampaignId.useInfiniteQuery(
    userCampaignListParams,
    {
      getNextPageParam: (lastPage) => {
        const nextCursor = lastPage?.nextCursor;
        return nextCursor;
      },
    }
  );

  const {
    data: targetContactRepos,
    fetchNextPage: fetchNextRepoPage,
    hasNextPage: hasNextRepoPage,
    isLoading: isLoadingRepo,
    isFetchingNextPage: isFetchingNextReposPage,
  } = api.targetContact.getTargetsByCampaignId.useInfiniteQuery(
    repoCampaignListParams,
    {
      getNextPageParam: (lastPage) => {
        const nextCursor = lastPage.nextCursor;
        return nextCursor;
      },
    }
  );

  const {
    data: ultraLightUsers,
    fetchNextPage: fetchNextUltraLightUserPage,
    hasNextPage: hasNextUltraLightUserPage,
    isLoading: isLoadingUltraLightUsers,
    isFetchingNextPage: isFetchingNextUltraLightUsersPage,
  } = api.targetContact.getUltraLightTargetsByCampaignId.useInfiniteQuery(
    {
      teamAccountId: activeTeamAccount.id,
      campaignId: campaignId as string,
      type: "user",
      sorting: {
        field: "_id",
        direction: "desc",
      },
      limit: 50,
    },
    {
      getNextPageParam: (lastPage) => {
        const nextCursor = lastPage.nextCursor;
        return nextCursor;
      },
    }
  );
  const key = "targetBatchCreationErrorCount".concat(
    (targetBatchCreationFailCounts?.totalErrorCount ?? "").toString()
  );
  const { dismiss, dismissed } = useDismissible(
    (campaignId as string) ?? activeTeamAccount.id,
    key
  );

  const { data } = api.targetBatchCreation.get.useQuery({
    teamAccountId: activeTeamAccount.id,
    campaignId: campaignId as string,
  });
  const userCount = data?.user ?? 0;
  const repoCount = data?.repo ?? 0;
  const totalNumberOfTargetsWaiting = userCount + repoCount;

  const handleGetNextTeamPage = () => {
    if (hasNextRepoPage) {
      fetchNextRepoPage().catch((err) =>
        logger.error(err, "TargetInfoDashboard.tsx1")
      );
    }
  };

  const handleGetNextUserPage = () => {
    if (hasNextUserPage) {
      fetchNextUserPage().catch((err) =>
        logger.error(err, "TargetInfoDashboard.tsx2")
      );
    }
  };

  const handleGetNextUltraLightUserPage = () => {
    if (hasNextUltraLightUserPage) {
      fetchNextUltraLightUserPage().catch((err) =>
        logger.error(err, "TargetInfoDashboard.tsx3")
      );
    }
  };
  const flatRepoData = useMemo(
    () => targetContactRepos?.pages?.flatMap((page) => page.items) ?? [],
    [targetContactRepos]
  );
  const flatUserData = useMemo(
    () => targetContactUsers?.pages?.flatMap((page) => page.items) ?? [],
    [targetContactUsers]
  );
  const flatUltraLightUserData = useMemo(
    () => ultraLightUsers?.pages?.flatMap((page) => page.items) ?? [],
    [ultraLightUsers]
  );

  useEffect(() => {
    if (flatRepoData && flatUserData && ultraLightUsers) {
      setLoading(false);
    }
  }, [flatRepoData, flatUserData, ultraLightUsers]);
  const hasTeams =
    (isLoadingRepo && flatRepoData?.length === 0) || flatRepoData?.length !== 0;

  const hasUsers =
    (isLoadingUsers && flatUserData?.length === 0) ||
    flatUserData?.length !== 0;

  const hasUltraLightUsers =
    (isLoadingUltraLightUsers && flatUltraLightUserData?.length === 0) ||
    flatUltraLightUserData?.length !== 0;

  if (Array.isArray(campaignId)) return null;
  const loadedWithCampaigns = campaignIds.length > 0 && !loading;
  const loadedWithNoCampaigns = campaignIds.length === 0 && !loading;

  const handleClick = () => {
    dismiss();
  };
  const { totalErrorCount } = targetBatchCreationFailCounts ?? {
    totalErrorCount: 0,
  };
  const readableType = type === "repo" ? "repository" : "user";
  const isUltraLight =
    campaignId && campaigns[0]?.mode === Enums.CampaignMode.ULTRALIGHT;

  return (
    <>
      {!dismissed && totalErrorCount !== 0 && activeHeaderMenu && (
        <Notification className="w-full">
          <div className="flex-1">
            Unable to add {totalErrorCount}{" "}
            {pluralize(readableType, totalErrorCount)}, this was due to the
            following reasons
            <ul className="list-style-disk">
              {Object.entries(targetBatchCreationFailCounts?.errorTypes ?? {})
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(([error, count]) => {
                  if (count === 0) return null;
                  return (
                    <li key={error}>
                      {readableErrorFromScreamingSnake(error)}: {count}{" "}
                      {pluralize(readableType, count)}
                      {error === "REPO_TOO_LARGE" &&
                        " (contributors were added)"}
                    </li>
                  );
                })}
            </ul>
          </div>
          <button className="align-self-top h-6" onClick={handleClick}>
            <XMarkIcon className="inline-block h-6 " />
          </button>
        </Notification>
      )}
      {activeHeaderMenu === "Teams" && (
        <>
          {hasTeams && (
            <>
              {showTable ? (
                <>
                  <EditSelection />
                  <TeamsTable
                    isLoadingRepo={isLoadingRepo}
                    isFetchingNextReposPage={isFetchingNextReposPage}
                    hasNextRepoPage={hasNextRepoPage}
                    handleGetNextTeamPage={handleGetNextTeamPage}
                    repoTargetMap={flatRepoData}
                  />
                </>
              ) : (
                flatRepoData && (
                  <CampaignTeams
                    handleGetNextTeamPage={handleGetNextTeamPage}
                    repoTargetMap={flatRepoData}
                    hasNextRepoPage={hasNextRepoPage}
                    isFetchingNextReposPage={isFetchingNextReposPage}
                  />
                )
              )}
            </>
          )}
        </>
      )}

      {activeHeaderMenu === "Contributors" &&
        hasUltraLightUsers &&
        isUltraLight && (
          <div>
            <UltraLightTable
              userTargetMap={flatUltraLightUserData}
              handleGetNextUserPage={handleGetNextUltraLightUserPage}
              hasNextUserPage={hasNextUltraLightUserPage}
              isFetchingNextUsersPage={isFetchingNextUltraLightUsersPage}
              isLoadingUsers={isLoadingUltraLightUsers}
            />
          </div>
        )}
      {activeHeaderMenu === "Contributors" && !isUltraLight && (
        <>
          {hasUsers && (
            <>
              {showTable ? (
                <>
                  <EditSelection />
                  <UsersTable
                    hasNextUserPage={hasNextUserPage}
                    isFetchingNextUsersPage={isFetchingNextUsersPage}
                    handleGetNextUserPage={handleGetNextUserPage}
                    userTargetMap={flatUserData}
                    isLoadingUsers={isLoadingUsers}
                    showEngagement={showEngagement}
                  />
                </>
              ) : (
                flatUserData && (
                  <CampaignUsers
                    userTargetMap={flatUserData}
                    handleGetNextUserPage={handleGetNextUserPage}
                    hasNextUserPage={hasNextUserPage}
                    isFetchingNextUsersPage={isFetchingNextUsersPage}
                  />
                )
              )}
            </>
          )}
        </>
      )}
      {(activeHeaderMenu === "Teams" ||
        activeHeaderMenu === "Contributors") && (
        <NoResults
          searchTerm={
            activeHeaderMenu === "Teams"
              ? repoCampaignListParams.searchTerm
              : userCampaignListParams.searchTerm
          }
          activeHeaderMenu={activeHeaderMenu}
          hasUsers={hasUsers}
          hasTeams={hasTeams}
          loadedWithCampaigns={loadedWithCampaigns}
          totalNumberOfTargetsWaiting={totalNumberOfTargetsWaiting}
          loadedWithNoCampaigns={loadedWithNoCampaigns}
        />
      )}
    </>
  );
};

export default TargetInfoDashboard;
