import DashboardCard from "~/components/campaigns/MainPage/Dashboard/DashboardCard";

const RankInsights = ({
  oneTimeDevs,
  fullTimeDevs,
  partTimeDevs,
}: {
  oneTimeDevs: number;
  fullTimeDevs: number;
  partTimeDevs: number;
}) => {
  const allActiveDevs = oneTimeDevs + fullTimeDevs + partTimeDevs;

  const oneTimePercent = (
    allActiveDevs ? (oneTimeDevs / allActiveDevs) * 100 : 0
  ).toFixed(2);
  const fullTimePercent = (
    allActiveDevs ? (fullTimeDevs / allActiveDevs) * 100 : 0
  ).toFixed(2);
  const partTimePercent = (
    allActiveDevs ? (partTimeDevs / allActiveDevs) * 100 : 0
  ).toFixed(2);

  return (
    <div className="py-6">
      <div className="flex justify-between pt-2 ">
        <h3 className="pb-6 text-xl font-semibold">Activity Insights</h3>
      </div>
      <p className="pb-4">
        Activity Insights are based on the past 6 weeks of the
        contributors&lsquo; activity.{" "}
      </p>
      <div className="flex flex-wrap gap-6">
        <DashboardCard
          leadText="Full-time"
          percentage={fullTimePercent?.toString() + "%"}
          metricName="Full-time developers"
          leads={fullTimeDevs?.toString()}
          colorLight="bg-mauve-100"
          colorMedium="text-mauve-200"
          colorDark="text-mauve-300"
          tooltipText="Developers who committed on at least 10 different days in the past 6 weeks"
          rank="full-time"
        />

        <DashboardCard
          leadText="Part-time"
          percentage={partTimePercent?.toString() + "%"}
          metricName="Part-time developers"
          leads={partTimeDevs?.toString()}
          colorLight="bg-mauve-100"
          colorMedium="text-mauve-200"
          colorDark="text-mauve-300"
          tooltipText="Developers who committed on more than one day in the past 6 weeks"
          rank="part-time"
        />

        <DashboardCard
          leadText="One-time"
          percentage={oneTimePercent?.toString() + "%"}
          metricName="One-time developers"
          leads={oneTimeDevs?.toString()}
          colorLight="bg-mauve-100"
          colorMedium="text-mauve-200"
          colorDark="text-mauve-300"
          tooltipText="Developers who committed on a single day in the past 6 weeks"
          rank="one-time"
        />
      </div>
    </div>
  );
};
export default RankInsights;
