import { useEffect } from "react";
import {
  useDependencyName,
  useDependencyNames,
  useSetDependencyName,
} from "./DependencyProviders";

export default function SelectDependency({
  defaultDependencyName,
}: {
  defaultDependencyName?: string;
}) {
  const dependencyName = useDependencyName();
  const dependencyNames = useDependencyNames();
  const setDependencyName = useSetDependencyName();

  function selectDependency(dependencyValue: string) {
    setDependencyName(dependencyValue);
  }

  useEffect(() => {
    if (!dependencyName && defaultDependencyName) {
      selectDependency(defaultDependencyName);
    } else if (!dependencyName && dependencyNames[0]) {
      selectDependency(dependencyNames[0]);
    }
  });

  return (
    <select
      id="claimed"
      name="claimed"
      className="input-field py-2"
      defaultValue={defaultDependencyName}
      onChange={(e) => selectDependency(e.target.value)}
    >
      {dependencyNames.length > 0 &&
        dependencyNames.map((dependencyName) => (
          <option key={dependencyName} value={dependencyName}>
            {dependencyName}
          </option>
        ))}
    </select>
  );
}
