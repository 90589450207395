import { useRouter } from "next/router";
import { type Dispatch, type SetStateAction } from "react";

import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api } from "~/utils/api";
import Link from "next/link";
import Image from "next/image";
import { Dialog } from "@headlessui/react";

export default function RankList({
  setShowModal,
  showModal,
  rank,
}: Readonly<{
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  rank: string;
}>) {
  const router = useRouter();
  const campaignId = router.query.campaignId as string;

  const { activeTeamAccount } = useTeamAccount();
  const teamAccountId = activeTeamAccount?.id;

  const { data: targetContacts } =
    api.targetContact.getTargetRanksByCampaignId.useQuery({
      campaignId,
      teamAccountId,
      rank,
    });

  const nbContributors = targetContacts?.length ?? 0;

  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black bg-opacity-50 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-16 py-4 text-left align-middle shadow-xl transition-all">
          <Dialog.Title className=" flex flex-col content-center items-center justify-center gap-2 text-lg leading-6 ">
            <span className="py-2 text-2xl">List of {rank} contributors</span>
          </Dialog.Title>
          <Dialog.Description>
            <div className="whitespace-normal px-12 py-8">
              <div>
                {nbContributors} developers are considered to be {rank}{" "}
                contributors.
              </div>
              <ul className="flex max-h-[500px] overflow-y-scroll list-disc flex-col space-y-3 p-8 pl-4">
                {targetContacts?.map((contact) => (
                  <Link
                    key={contact.id}
                    href={`/target/user/${contact.teamTargetContactId}/commits/activity`}
                    target="_blank"
                    className="py-3"
                  >
                    <div className="flex items-center gap-3">
                      <Image
                        src={contact.ownerAvatarUrl}
                        alt={contact.githubName}
                        width={48}
                        height={48}
                        className="h-12 w-12 rounded-full"
                      />

                      <div className="text-lg font-semibold">
                        {contact.githubName}
                      </div>
                    </div>
                  </Link>
                ))}
              </ul>
            </div>
          </Dialog.Description>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
