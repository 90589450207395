import Notification from "~/components/base/Notification";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import { Constants } from "@openqlabs/utils";

export default function CampaignEvalProgress({
  waitingEvalsCount,
  doneEvalsCount,
}: Readonly<{
  waitingEvalsCount: number;
  doneEvalsCount: number;
}>) {
  const previousResults = doneEvalsCount === 0;
  const percentageDone = doneEvalsCount / (doneEvalsCount + waitingEvalsCount);
  const considerDone = percentageDone > Constants.PERCENTAGE_CONSIDER_DONE;
  if (considerDone) return null;
  return (
    <Notification>
      The campaign evaluation is still processing. Any insights showing below
      are preliminary and based on{" "}
      {previousResults ? (
        "previous evaluations."
      ) : (
        <>
          {doneEvalsCount} / {doneEvalsCount + waitingEvalsCount} evaluations
          performed.
        </>
      )}{" "}
      <LoadingSpinner className="h-8 w-8 px-2 text-gray-700" />
    </Notification>
  );
}
