import DashboardCard from "~/components/campaigns/MainPage/Dashboard/DashboardCard";
import { useCampaign } from "~/providers/CampaignProvider";
import { InfoIcon } from "@primer/octicons-react";
import SelectDependency from "./SelectDependency";

const LightDashboard = ({
  leads,
  customers,
  repoCount,
  userCount,
  dependency,
}: {
  leads: number;
  customers: number;
  repoCount: number;
  userCount: number;
  dependency: string;
}) => {
  const activeUsers = leads + customers;
  const campaign = useCampaign();
  const { budget } = campaign;

  const costPerActiveUser = (budget / activeUsers).toFixed(0);
  const noActiveUsers = activeUsers === 0;
  const activeUsersTitle = noActiveUsers ? "N/A" : `$${costPerActiveUser}`;

  return (
    <div>
      <div className="max-w-[720px]">
        <div className="flex  justify-between pt-2">
          <div className="flex items-center">
            <div className="group flex items-center  gap-2">
              <h3 className="flex  text-xl font-semibold">Light Insights </h3>
              <InfoIcon />
              <div className="relative z-50 opacity-0 group-hover:opacity-100">
                <div className="absolute -mt-6 ml-4 w-96 bg-gray-100 px-1 text-sm">
                  These light insights are currently based on an analysis of
                  max. 7 repos to which each contributor has committed to in the
                  past 6 weeks. In our closed beta, we also filter out
                  repositories exceeding 100 MB in size. Due to these current
                  settings, although the majority of insights will be accurate,
                  some users may be appearing to be inactive when they are not.
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 font-medium">
            <div>Budget</div>
            <div className="h-min rounded-full bg-mauve-100 px-3 py-0.5 text-sm ">
              ${budget}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center pt-2 pb-4">
          <p>
            Light Insights are based on the past 6 weeks of the
            contributors&lsquo; activity.
          </p>
          <SelectDependency defaultDependencyName={dependency} />
        </div>
      </div>

      <div className="flex flex-wrap gap-6 ">
        <DashboardCard
          leadText="Evaluated users & teams"
          percentage={repoCount?.toString()}
          metricName="Total users & teams"
          leads={`${userCount}`}
          colorLight="bg-mint-100"
          colorMedium="text-mint-200"
          colorDark="text-mint-300"
        />
        {userCount > 0 && (
          <DashboardCard
            leadText="Active contributors"
            percentage={
              ((activeUsers / userCount) * 100).toFixed(2).toString() + "%"
            }
            metricName="Total active users"
            leads={activeUsers.toString()}
            colorLight="bg-mint-100"
            colorMedium="text-mint-200"
            colorDark="text-mint-300"
          />
        )}
        <DashboardCard
          leadText="Leads & customers"
          leads={activeUsersTitle}
          metricName="Cost per active user"
          colorLight="bg-mint-100"
          colorMedium="text-mint-200"
          colorDark="text-mint-300"
        />
      </div>
    </div>
  );
};
export default LightDashboard;
